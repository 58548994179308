import React from 'react'
import {
  Box, Typography, styled,
} from '@mui/material';
// import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { dataConstants } from "../../../constants";
// import { withStyles } from '@mui/styles';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
// import { getRandomStr } from '../../../helpers';

const { PLAN_QANDA, } = dataConstants || {};

const replacements = [
  { tag: '@grufityreports', link: 'https://twitter.com/grufityreports' },
  { tag: '@grufity', link: 'https://twitter.com/grufity?lang=en' },
  { tag: 'support@grufity.com', link: 'mailto:support@grufity.com?subject=Regarding%20Pricing' },
  { tag: 'https://www.grufity.com/docs/api', link: 'https://www.grufity.com/docs/api' }
];

const replaceTagsWithLinks = (inputText, replacements) => {
  return replacements.reduce((result, { tag, link }) => {
    const lowercaseTag = tag.toLowerCase();
    const regex = new RegExp(`(?<!\\w)${lowercaseTag}(?!\\w)`, 'gi');
    return result.replace(regex, `<a href="${link.toLowerCase()}" target="_blank" rel="noreferrer noopener nofollow">${tag}</a>`);
  }, inputText);
};

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  boxShadow: 'none',
  border: '1px solid #fff',
  borderRadius: '7px',
  '&:before': {
    display: 'none',
  },
  '&.MuiPaper-root.Mui-expanded': {
    borderRadius: '7px',
    border: '1px solid #eae9ee',
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  // backgroundColor: '#fafafa',
  backgroundColor: '#fff',
  marginBottom: -1,
  minHeight: 52,
  '&.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: 52,
    borderBottom: '1px solid #eae9ee',
    // backgroundColor: 'rgba(0, 0, 0, .03)',
    backgroundColor: '#FBFBFC',
    borderRadius: '6px 6px 0 0',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '12px 0',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '16px',
}));

const FAQs = ({ pricePlans, header }) => {
  // const [accordionExpanded, setAccordionExpanded] = useState('question0');
  const plan = pricePlans && pricePlans.length > 0 ? pricePlans?.find(d => d.type === 'PREMIUM') : PLAN_QANDA;
  const qAndA = plan && plan?.features ? JSON.parse(plan?.features).qAndA : PLAN_QANDA;

  const currentDate = new Date();
  const fiveDaysAgo = new Date(currentDate);
  fiveDaysAgo?.setDate(currentDate?.getDate() - 5);

  const dateModified = fiveDaysAgo?.toISOString() ?? "";

  // const handleAccordion = (panel) => {
  //   setAccordionExpanded(panel);
  // };
  return (
    <>
      <Box id="faq" component="div" sx={{ p: { md: '1rem 3rem', xs: '1.5rem' } }} itemScope itemType="https://schema.org/FAQPage">
        <Box copmonent="div">
          {header}
          <Box copmonent="div" sx={{ mt: 5, mb: 20 }}>
            {qAndA && qAndA.length > 0 && qAndA.map((qa, ind) => {
              return (qa.a && qa.a !== "" ?
                <Accordion defaultExpanded itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
                  <AccordionSummary
                    aria-controls={`question${ind}-content`}
                    id={`question${ind}-header`}
                    expandIcon={<ExpandMoreRoundedIcon sx={{ color: '#174c86', }} />}
                  >
                    <Typography variant="p" component="h3" sx={{ lineHeight: 1.7, letterSpacing: '0.00938em', fontSize: '.875rem', color: '#174c86' }} className="font-weight-bold" dangerouslySetInnerHTML={{ __html: qa.q }} itemProp="name" />
                  </AccordionSummary>
                  <AccordionDetails itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
                    <Typography variant="p" component="p"
                      sx={{ lineHeight: 1.7, letterSpacing: '0.00938em', fontSize: '.875rem', color: '#434166', '& a': { textDecoration: 'none', color: "#174ea6" }, '& a:hover': { textDecoration: 'underline' } }}
                      dangerouslySetInnerHTML={{ __html: replaceTagsWithLinks(qa.a, replacements) }} itemProp="text"
                    />
                  </AccordionDetails>
                  {/* <meta itemprop="dateModified" content={dateModified} /> */}
                </Accordion> : null)
            })}
          </Box>
        </Box>
        {/* <meta itemprop="dateModified" content={dateModified} /> */}
      </Box>
    </>
  );
};


export default (FAQs);
