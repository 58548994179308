import {
  useStripe,
  // useElements, Elements
} from "@stripe/react-stripe-js";
import {
  Box, Button, Typography, Chip,
  Paper, InputBase, Divider, IconButton, CircularProgress, ToggleButtonGroup, ToggleButton,
  FormHelperText, ClickAwayListener, FormControl, RadioGroup, FormControlLabel, Radio,
  // Breadcrumbs, useMediaQuery, CardHeader, Alert, Grid, Stack, ToggleButton, ToggleButtonGroup
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { dataConstants } from '../../../constants';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import useAuth from '../../../hook/auth';
import { checkSubscription, createCheckout, timeout } from "../../../utils/helpers";
// import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
// import OutboundRoundedIcon from '@mui/icons-material/OutboundRounded';
// import HomeIcon from '@mui/icons-material/Home';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import SellRoundedIcon from '@mui/icons-material/SellRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import * as send from '../../../../lib/analytics/send';

// Inspired by blueprintjs
function StyledRadio(props) {
  // const classes = useStyles();

  return (
    <Radio
      sx={{
        color: '#174c86',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
      // className={classes.root}
      disableRipple
      // color="default"
      checkedIcon={<span sx={{
        backgroundColor: '#174c86',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
          display: 'block',
          width: 16,
          height: 16,
          backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
          content: '""',
        },
        'input:hover ~ &': {
          backgroundColor: '#106ba3',
        },
      }}
      // className={clsx(classes.icon, classes.checkedIcon)}
      />}
      icon={<span sx={{
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
          backgroundImage: '#0d6efd',
        },
        'input:hover ~ &': {
          backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
          boxShadow: 'none',
          background: 'rgba(206,217,224,.5)',
        },
      }}
      // className={classes.icon}
      />}
      {...props}
    />
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  // background: 'linear-gradient(to right, #174ea6, #020B4A)',
  background: '#174ea6',
  borderRadius: '9px',
  '.MuiToggleButton-root.Mui-selected': {
    background: '#fff',
    boxShadow: 2,
    color: 'hsl(235deg 59.62% 21.27%)' /*#020B4A*/
  },
  '& .MuiToggleButton-root': {
    textTransform: 'none',
    lineHeight: 1,
    padding: '10px 16px !important',
    color: 'rgba(255,255,255,0.77)',
    fontWeight: 600,
  },
  '& .MuiToggleButton-root.Mui-selected:hover': {
    background: 'rgba(255,255,255,0.90)'
  },
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.4, 0.38),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: '7px',
    },
    '&:first-of-type': {
      borderRadius: '6px',
    },
  },
}));

const { PLAN_MODES, PLAN_CYCLES, PLAN_QANDA, PLAN_COMPARISON, PLAN_TYPES } = dataConstants || {};

const LandingCheckout = ({ pricePlans, }) => {
  const { user } = useAuth() || {}
  const router = useRouter()
  const { reloadUserInfo } = user || {}
  const { customAttributes, emailVerified, email, passwordUpdatedAt } = reloadUserInfo || {}
  const { userType, stripe_customer_id } = customAttributes ? JSON.parse(customAttributes) : {}
  const isPremium = reloadUserInfo?.customAttributes && (JSON.parse(reloadUserInfo?.customAttributes)?.userType === "PREMIUM" || JSON.parse(reloadUserInfo?.customAttributes)?.userType === "PROFESSIONAL") ? true : false;

  // const staticText = ["Experience"]
  // const words = ['Real-time Data..', 'Reliable Data..', 'Powerful Data..',]
  const pricing_comparison_rows = PLAN_COMPARISON;
  const qAndA = pricePlans && pricePlans.length > 0 && pricePlans[0]?.features ? JSON.parse(pricePlans[0]?.features).qAndA : PLAN_QANDA;
  const [accordionExpanded, setAccordionExpanded] = useState('question0');
  // const [value, setValue] = useState(0);
  const [plan, setPlan] = useState("Premium");
  const [plans, setPlans] = useState(pricePlans ? pricePlans : (process.env.NODE_ENV !== 'production' ? dataConstants.DEVPLANS : dataConstants.PRODPLANS));
  // const [toggleBillingCycle, setToggleBillingCycle] = useState("monthly");
  // const [planSwitch, setPlanSwitch] = useState(true);
  // const [planMode, setPlanMode] = useState(PLAN_MODES.NORMAL);
  const [planCycle, setPlanCycle] = useState(PLAN_CYCLES.ANNUALLY);
  const [showSearch, setShowSearch] = useState(false);
  const pMode = 'Normal';
  const planItem = plans && plans.length > 0 ? plans?.find(d => d.plan.toLowerCase() === plan.toLowerCase()) : null;
  const [priceID, setPriceID] = useState(planItem?.ypid);
  const [priceIdLoading, setPriceIdLoading] = useState();
  const [searchType, setSearchType] = useState('STOCK');
  const [currency_type, set_currency_type] = useState('usd');
  const [showCoupon, setShowCoupon] = useState(false);
  const [coupon, setCoupon] = useState({
    hasCode: false,
    code: '',
    loading: false,
    isValid: false,
  });
  const [hasPastSubscriptions, setPastSubscriptions] = useState(false);
  // const splitPriceMonthly = planItem && currency_type === "inr" ? planItem?.im.toString().split('.') : planItem?.m.toString().split('.');
  // let splitPriceQuarterly = planItem && currency_type === "inr" ? (coupon?.q_final_amount ?? planItem?.iq) / 3 : (coupon?.q_final_amount ?? planItem?.q) / 3;
  // splitPriceQuarterly = splitPriceQuarterly ? splitPriceQuarterly?.toFixed(2)?.toString().split('.') : null;
  // let splitPriceAnnually = planItem && currency_type === "inr" ? (coupon?.y_final_amount ?? planItem?.iy) / 12 : (coupon?.y_final_amount ?? planItem?.y) / 12;
  // splitPriceAnnually = splitPriceAnnually ? splitPriceAnnually.toFixed(2).toString().split('.') : null;
  // currency_type = `${currency_type}`.includes('India Standard Time') ? "inr" : "usd";
  // const year_currency_options = yearlyPlan?.currency_options[currency_type] || {}
  // const month_currency_options = monthlyPlan?.currency_options[currency_type] || {}

  const splitPriceMonthly = planItem?.m?.toString().split('.');

  let splitPriceQuarterly = planItem && currency_type === "inr" ? (coupon?.q_final_amount ?? planItem?.iq) / 3 :
    planItem && currency_type === "eur" ? (coupon?.q_final_amount ?? planItem?.eq) / 3 :
      planItem && currency_type === "gbp" ? (coupon?.q_final_amount ?? planItem?.gq) / 3 :
        (coupon?.q_final_amount ?? planItem?.q) / 3;
  splitPriceQuarterly = splitPriceQuarterly !== 0 ? (Number(splitPriceQuarterly)?.toFixed(2)?.toString().split('.')) : null;

  // let splitPriceAnnually = planItem && currency_type === "inr" ? (coupon?.y_final_amount ?? planItem.iy) / 12 :
  //   planItem && currency_type === "eur" ? (coupon?.y_final_amount ?? planItem?.ey) / 12 :
  //     planItem && currency_type === "gbp" ? (coupon?.y_final_amount ?? planItem?.gy) / 12 :
  let splitPriceAnnually = (coupon?.y_final_amount ?? planItem?.y) / 12;

  // Handling the case of 100% discount coupon
  if (coupon?.y_final_amount === 0) {
    splitPriceAnnually = [0, '00'];
  } else if (splitPriceAnnually === 0) {
    splitPriceAnnually = [0, '00'];
  } else {
    splitPriceAnnually = splitPriceAnnually !== 0 ? (Number(splitPriceAnnually)?.toFixed(2)?.toString().split('.')) : null;
  }

  useEffect(() => {
    const currentDate = new Date();
    if (`${currentDate}`.includes('India Standard Time')) {
      set_currency_type('inr')
    } else {
      set_currency_type('usd')
    }
  }, [new Date()]);

  const stripe = useStripe();

  const handleClick = event => {
    event.preventDefault();
  };

  // const handleBillingChange = bc => {
  //   setPriceID(bc.target.value)
  //   setPlanCycle(bc.target.value);
  // };

  const handleBillingChange = (event, newCycle) => {
    setPriceID(dataConstants.PLAN_CYCLES.ANNUALLY === event.target.value ? planItem?.ypid :
      (dataConstants.PLAN_CYCLES.QUARTERLY === event.target.value ? planItem?.qpid : planItem?.mpid))
    if (newCycle !== null) {
      setPlanCycle(newCycle);
    }
  };

  const handleCycle = (event, newCycle) => {
    if (newCycle !== null) {
      setPlanCycle(newCycle);
    }
  };

  const handleCheckout = async (priceID) => {
    setPriceIdLoading(priceID);
    if (!user) {
      return router.push({
        pathname: '/auth',
        query: { from: router.asPath },
      });
    }
    if (isPremium) {
      return router.push('/profile');
    }
    try {
      if (user && user?.email) {
        const { sessionId } = await createCheckout({
          url: '/api/create-checkout-session',
          priceID: priceID,
          email: user?.email,
          coupon: dataConstants.PLAN_CYCLES.ANNUALLY === planCycle ? (coupon?.id ?? coupon?.code) : '',
          oldUser: ((stripe_customer_id && userType === "FREE") || hasPastSubscriptions)
        });
        // const stripe = await getStripe();
        stripe === null || stripe === void 0 ? void 0 : stripe.redirectToCheckout({ sessionId });
      }
    }
    catch (error) {
      return alert(error === null || error === void 0 ? void 0 : error.message);
    }
    finally {
      setPriceIdLoading(undefined);
    }
  };

  const handleCoupon = (prop) => (event) => {
    delete coupon?.m_final_amount
    delete coupon?.y_final_amount
    setCoupon({
      ...coupon,
      [prop]: event.target.value,
      loading: false,
      isValid: false,
    });
  };

  const handleCouponCode = async () => {
    await timeout(1000);
    setShowCoupon(coupon?.errText ? false : true)
  }

  const enterCoupon = () => {
    setCoupon({
      ...coupon,
      hasCoupon: !coupon.hasCoupon,
    });
  }

  const getCoupon = async (event) => {
    event.preventDefault();
    setCoupon({
      ...coupon,
      loading: true,
    });
    await fetch(`${process.env.NEXT_PUBLIC_PAY_URL}/promotion?code=${coupon?.code}`)
      .then((res) => {
        if (!res.ok) {
          return res.text().then(text => {
            setCoupon({ ...coupon, errText: text })
            throw new Error(text)
          })
        }
        else {
          return res.json();
        }
      })
      .then((d) => {
        const data = d?.data[0]
        d?.data <= 0 ?
          setCoupon({ ...coupon, errText: 'Not Found' }) :
          setCoupon({
            ...coupon,
            ...data?.coupon,
            isValid: data?.coupon?.id === coupon?.code ? data?.coupon?.valid ?? false : false,
            loading: false,
            m_final_amount: Math.round((((planItem && currency_type === "inr" ? planItem?.iy : planItem?.y) / 12) * data?.coupon?.percent_off) / 100) ?? null,
            // y_final_amount: Math.round(((planItem && currency_type === "inr" ? planItem?.iy : planItem?.y) * data?.percent_off) / 100) ?? null,
            y_final_amount: Math.round((planItem && currency_type === "inr" ? planItem?.iy : planItem?.y) * (1 - (data?.coupon?.percent_off / 100))),
          });
        handleCouponCode(data && d?.data <= 0 ? false : true)
      })
      .catch(err => {
        console.log('caught it!', err);
      });
  }

  const getCurrencySymbol = (currency_type) => {
    switch (currency_type) {
      case "inr":
        return "₹";
      case "gbp":
        return "£";
      case "eur":
        return "€";
      default:
        return "$";
    }
  }

  const symbol = getCurrencySymbol(currency_type);

  useEffect(() => {
    if (!stripe_customer_id && email) {
      checkSubscription(email)
        .then(d => setPastSubscriptions(d?.hasPastSubscriptions))
        .catch(err => console.error('check subscription err', err.message));
    }
  }, [stripe_customer_id, email]);

  return (
    <div className="pri-billing-content">
      {/* <Chip
        sx={{
          color: '#49a462', backgroundColor: '#d1eedc', borderRadius: '5px', fontSize: '0.68rem',
          fontWeight: '600', ml: '3px', mb: '1px', mt: 2,
          '&:hover, &:active, &:focus': {
            backgroundColor: '#fff',
          },
        }}
        // className={`discount-chip`}
        size="small"
        label={`USE CODE "50OFF"`}
      />
      <Chip
        sx={{
          color: 'text.description', borderRadius: '5px', fontSize: '0.68rem',
          fontWeight: '600', ml: '3px', mb: '1px', mt: 2, backgroundColor: '#fafafa',
        }}
        size="small"
        label={`AND GET ADDITIONAL 50% OFF ON ANNUAL PLAN`}
      /> */}
      {/* <Box sx={{ width: '14rem', m: 'auto', mt: 3 }} >
        <Paper
          component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', }}
          onSubmit={getCoupon}
        >
          <InputBase
            // disabled={true}
            id="coupon-code"
            sx={{ ml: 1, flex: 1, fontSize: '.87rem' }}
            placeholder="Enter Coupon Code"
            value={coupon.code}
            onChange={handleCoupon('code')}
            inputProps={{ 'aria-label': 'code' }}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          {coupon?.isValid ?
            <IconButton color="success" sx={{ p: '5px' }} aria-label="verified">
              <CheckCircleRoundedIcon />
            </IconButton> :
            coupon?.loading ?
              <CircularProgress sx={{ p: '5px', width: '30px !important', height: '30px !important' }} /> :
              <Button size="small" sx={{ color: 'text.link', fontWeight: 600 }} aria-label="submit" onClick={getCoupon} type="submit">
                Apply
              </Button>}
        </Paper>
        {coupon?.isValid ?
          <FormHelperText id="coupon-code" sx={{ color: '#49a462', ml: .5 }} >
            {`coupon code applied successfully`}
          </FormHelperText> :
          <FormHelperText id="coupon-code" sx={{ color: 'text.very_light_desc', ml: .5 }} error={coupon?.errText}>
            {coupon?.errText ?? `Type coupon code and press  [Enter]`}
          </FormHelperText>}
      </Box> */}
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          // border: (theme) => `1px solid rgb(42 45 100)`,
          flexWrap: 'wrap',
          // borderRadius: '6px'
          justifyContent: 'center', my: 3,
        }}
      >
        <StyledToggleButtonGroup
          size="small"
          value={planCycle}
          exclusive
          onChange={(event, newCycle) => { handleCycle(event, newCycle); handleBillingChange(event, newCycle) }}
          aria-label="plan-switch"
        >
          <ToggleButton value="Annually" aria-label="yearly-plan">
            Yearly
          </ToggleButton>
          <ToggleButton value="Monthly" aria-label="monthly-plan">
            Monthly
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
      <FormControl
        sx={{ width: '100%', }}
        component="fieldset"
      >
        <RadioGroup
          className="gruf-pricing-options"
          defaultValue="Monthly"
          value={planCycle}
          // value={priceID}
          aria-label="premium"
          name="customized-radios"
          // onChange={planItem ? (planItem?.type === "FREE" ? handleClick : handleBillingChange) : handleClick}
          onChange={(event, newCycle) => handleBillingChange(event, newCycle)}
        >
          {planCycle === "Annually" &&
            <FormControlLabel
              sx={{
                transition: 'all .5s ease-in-out',
                '&$checked': {
                  color: 'blue',
                },
                '&:hover': {
                  boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
                  transition: 'box-shadow .3s ease-in-out',
                },
                '&.MuiFormControlLabel-root': {
                  justifyContent: 'center'
                },
                padding: '1rem',
                marginTop: '16px',
                marginLeft: '0',
                marginRight: '0',
                backgroundColor: '#cbdaf1',
                borderRadius: '5px',
                billingText: {
                  marginLeft: 'auto',
                }
              }}
              component="div"
              className={` flex-column pricing-toggle-button ${!isPremium && priceID === planItem?.ypid ? 'selected' : ''}`}
              value="Annually"
              control={<StyledRadio className="d-none" />}
              label={
                <React.Fragment >
                  <Chip
                    sx={{
                      color: '#174ea6',
                      backgroundColor: '#fff',
                      borderRadius: '5px',
                      fontSize: '0.68rem',
                      fontWeight: '600',
                      marginLeft: '3px',
                      marginBottom: '1px',
                      '&:hover, &:active, &:focus': {
                        backgroundColor: '#fff',
                      },
                    }}
                    className={`discount-chip`}
                    size="small"
                    label={`POPULAR`}
                  />
                  <Box className="flex-column" color="inherit" component="div" display="flex" sx={{ flexDirection: 'column', }}>
                    {planItem && planItem.y && splitPriceAnnually ?
                      <Box className="text-dark text-center billed-annually-price" component="div" >
                        <Box className="billing-dollar" color="inherit" component="span">{`${symbol}`}</Box>
                        <Box className="NumberBeforeDot" color="inherit" component="span">{Math.trunc(splitPriceAnnually[0])}</Box>
                        <Box className="NumberAfterDot" color="inherit" component="span">{`.${splitPriceAnnually[1] || '00'}`}</Box>
                        <Box fontWeightLight className="text-muted NumberAfterDot" fontSize="fontSize" component="span" >{`/mo`}</Box>
                      </Box>
                      :
                      <Box color="inherit" component="div" className="text-dark text-center billed-annually-price" >
                        <Box className="billing-dollar" color="inherit" component="span">{`${symbol}`}</Box>
                        <Box className="NumberBeforeDot" color="inherit" component="span">{(Math.round(currency_type === "inr" ? planItem?.iy : planItem?.y) / 12).toFixed(2)}</Box>
                        {/* {`${symbol}${(Math.round((currency_type === "inr" ? planItem?.iy : planItem?.y) / 12)) - 0.01}`} */}
                        <Box fontWeightLight className="text-muted NumberAfterDot" fontSize="fontSize" component="span" >{`/mo`}</Box>
                      </Box>
                    }
                    <Box color="inherit" component={"div"}>
                      {planItem && planItem.m &&
                        <Box fontWeightLight className="text-muted annually-text my-2" fontSize="fontSize" sx={{ mt: 2, color: 'text.description', '& del': { fontSize: '.87rem', color: 'text.very_light_desc' }, textAlign: 'center' }}>
                          <Typography variant="inherit" component={coupon?.y_final_amount >= 0 ? "del" : "div"} sx={{}}>
                            {/* {`${symbol}${currency_type === "inr" ? planItem?.iy : planItem?.y}`} */}
                            {`${symbol}${planItem?.y}`}
                            {coupon?.y_final_amount ? `` : ` billed yearly`}
                          </Typography>
                          {coupon?.y_final_amount >= 0 &&
                            <Typography variant="inherit" color="#49a462">
                              {`${symbol}${coupon?.y_final_amount} billed yearly`}
                            </Typography>}
                        </Box>}
                    </Box>
                  </Box>
                </React.Fragment>}
            />}
          {/* <FormControlLabel
            sx={{
              '&$checked': {
                color: 'blue',
              },
              '&:hover': {
                boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
                transition: 'box-shadow .3s ease-in-out',
              },
              padding: '1rem',
              marginTop: '16px',
              marginLeft: '0',
              marginRight: '0',
              backgroundColor: '#cbdaf1',
              borderRadius: '5px',
              billingText: {
                marginLeft: 'auto',
              }
            }}
            className={` flex-column pricing-toggle-button ${!isPremium && priceID === planItem?.qpid ? 'selected' : ''}`}
            // value={planItem.type === "FREE" ? "disabled" : PLAN_CYCLES.MONTHLY}
            value="Quarterly"
            control={<StyledRadio className="d-none" />}
            label={
              <React.Fragment>
                <Box className="flex-column" color="inherit" component="div" display="flex" sx={{ flexDirection: 'column', justifyContent: 'center', alignitems: 'center' }}>
                  {planItem && planItem.q && splitPriceQuarterly ?
                    <Box className="text-dark text-center billed-monthly-price" component="div" >
                      <Box className="billing-dollar" color="inherit" component="span">{`${symbol}`}</Box>
                      <Box className="NumberBeforeDot" color="inherit" component="span">{splitPriceQuarterly[0]}</Box>
                      <Box className="NumberAfterDot" color="inherit" component="span">{`.${splitPriceQuarterly[1] || '00'}`}</Box>
                      <Box fontWeightLight className="text-muted NumberAfterDot" fontSize="fontSize" component="span" >{`/mo`}</Box>
                    </Box>
                    :
                    <Box color="inherit" component="div">
                      {`${symbol}${currency_type === "inr" ? planItem?.iq : planItem?.q}`}
                    </Box>
                  }
                  <Box color="inherit" component="div">
                    {planItem &&
                      <Box fontWeightLight className="text-muted monthly-text my-2" fontSize="fontSize" sx={{ mt: 2, textAlign: 'center', color: 'text.description' }}>
                        {`${symbol}${currency_type === "inr" ? planItem?.iq : planItem?.q} billed quarterly`}
                      </Box>}
                  </Box>
                </Box>
              </React.Fragment>}
          /> */}
          {planCycle === "Monthly" &&
            <FormControlLabel
              sx={{
                '&$checked': {
                  color: 'blue',
                },
                '&:hover': {
                  boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
                  transition: 'box-shadow .3s ease-in-out',
                },
                padding: '1rem',
                marginTop: '16px',
                marginLeft: '0',
                marginRight: '0',
                backgroundColor: '#cbdaf1',
                borderRadius: '5px',
                billingText: {
                  marginLeft: 'auto',
                }
              }}
              className={` flex-column pricing-toggle-button ${!isPremium && priceID === planItem?.mpid ? 'selected' : ''}`}
              // value={planItem.type === "FREE" ? "disabled" : PLAN_CYCLES.MONTHLY}
              value="Monthly"
              control={<StyledRadio className="d-none" />}
              label={
                <React.Fragment>
                  <Box className="flex-column" color="inherit" component="div" display="flex" sx={{ flexDirection: 'column', justifyContent: 'center', alignitems: 'center' }}>
                    {planItem && planItem.m && splitPriceMonthly ?
                      <Box className="text-dark text-center billed-monthly-price" component="div" >
                        <Box className="billing-dollar" color="inherit" component="span">{`${symbol}`}</Box>
                        <Box className="NumberBeforeDot" color="inherit" component="span">{splitPriceMonthly[0]}</Box>
                        <Box className="NumberAfterDot" color="inherit" component="span">{`.${splitPriceMonthly[1] || '00'}`}</Box>
                        <Box fontWeightLight className="text-muted NumberAfterDot" fontSize="fontSize" component="span" >{`/mo`}</Box>
                      </Box>
                      :
                      <Box color="inherit" component="div">
                        {`${symbol}${currency_type === "inr" ? planItem?.im : planItem?.m}`}
                      </Box>
                    }
                    <Box color="inherit" component="div">
                      {planItem &&
                        <Box fontWeightLight className="text-muted monthly-text my-2" fontSize="fontSize" sx={{ mt: 2, textAlign: 'center', color: 'text.description' }}>
                          {/* {`${symbol}${currency_type === "inr" ? planItem?.im : planItem?.m} billed monthly`} */}
                          {`${symbol}${planItem?.m} billed monthly`}
                        </Box>}
                    </Box>
                  </Box>
                </React.Fragment>}
            />}
        </RadioGroup>
      </FormControl>
      <Box component="div" sx={{ m: '0 0 1rem 0 !important', textAlign: 'center' }}>
        {showCoupon ?
          <Box
            sx={{ ml: 'auto', textAlign: 'center' }}
          >
            <Chip
              sx={{ borderRadius: '8px', '& .MuiChip-label': { fontWeight: 600, mx: 1 }, background: dataConstants.PLAN_CYCLES.ANNUALLY != planCycle ? '#dc27272d' : '' }}
              // color="success"
              icon={<SellRoundedIcon fontSize="small" sx={{ fontSize: '1rem !important' }} />}
              label={coupon?.code ?? ''}
              onDelete={() => {
                setCoupon({
                  hasCode: false,
                  code: '',
                  loading: false,
                  isValid: false,
                });
                setShowCoupon(false);
              }}
              deleteIcon={<ClearRoundedIcon fontSize="small" sx={{ fontSize: '1.1rem !important' }} />}
            />
            <FormHelperText id="coupon-code" sx={{ color: dataConstants.PLAN_CYCLES.ANNUALLY != planCycle || coupon?.errText ? '#dc2727 ' : '#49a462', ml: .5, textAlign: 'center' }} >
              {dataConstants.PLAN_CYCLES.ANNUALLY != planCycle ? `Valid only on yearly plan` : `${coupon?.name ?? coupon?.errText}`}
            </FormHelperText>
          </Box>
          : !coupon.hasCoupon ?
            // dataConstants.PLAN_CYCLES.ANNUALLY === planCycle &&
            <Button onClick={enterCoupon}
              sx={{
                my: 1.8, textTransform: 'none', color: 'text.link',
                // visibility: dataConstants.PLAN_CYCLES.ANNUALLY != planCycle ? 'hidden' : 'visible'
              }}
              disabled={dataConstants.PLAN_CYCLES.ANNUALLY != planCycle}
            >
              Add coupon code
            </Button> :
            <Box
              sx={{ width: '14rem', m: 'auto', }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={(e) => coupon?.errText ? e.preventDefault() : enterCoupon()}
                >
                  <Paper
                    elevation={0}
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', border: '1px solid #174c842d' }}
                    onSubmit={getCoupon}
                  >
                    <InputBase
                      // disabled={true}
                      id="coupon-code"
                      sx={{ ml: 1, flex: 1, fontSize: '.87rem' }}
                      placeholder="Enter Coupon Code"
                      value={coupon.code}
                      onChange={handleCoupon('code')}
                      inputProps={{ 'aria-label': 'code' }}
                    />
                    <Divider sx={{ height: 28, m: 0.5, borderColor: '#fff' }} orientation="vertical" />
                    {coupon?.valid ?
                      <IconButton color="success" sx={{ p: '5px', ml: 2 }} aria-label="verified">
                        <DoneRoundedIcon />
                      </IconButton> :
                      coupon?.loading ?
                        <CircularProgress sx={{ p: '5px', width: '30px !important', height: '30px !important', ml: 2 }} /> :
                        <Button size="small" sx={{ color: 'text.link', textTransform: 'none' }} aria-label="submit" onClick={getCoupon} type="submit">
                          Apply
                        </Button>}
                  </Paper>
                </ClickAwayListener>
              </Box>
              {coupon?.valid ?
                <FormHelperText id="coupon-code" sx={{ color: '#49a462', ml: .5 }} >
                  {`coupon code applied successfully`}
                </FormHelperText> :
                <FormHelperText id="coupon-code" sx={{ color: 'text.very_light_desc', ml: .5 }} error={coupon?.errText}>
                  {coupon?.errText ?? `Type coupon code and press  [Enter]`}
                </FormHelperText>}
            </Box>}
      </Box>
      <Button
        variant="contained" data-event-name="checkout-btn" data-event-category="homepage" data-event-label={planCycle}
        sx={{ textTransform: "capitalize", fontSize: '1rem', background: '#174ea6', '&:hover': { background: '#174ea6', } }}
        onClick={(event) => {
          // router.push("/auth?user=!premium&redirect_to=stripe&plan=" + planCycle.toLowerCase().replaceAll('"', ''))
          handleCheckout(priceID);
          send.uevent(event);
        }}
      >
        {isPremium ? 'Manage Subscription' : ((stripe_customer_id && userType === "FREE") || hasPastSubscriptions) ? 'Buy Subscription' : 'Try it for just $1.99'}
      </Button>
    </div>
  )
}

export default LandingCheckout
