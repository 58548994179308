import React from "react";
import {
  Paper, IconButton,
  Box, Chip, ListSubheader, Popper, Tooltip, Typography, Zoom, useMediaQuery, TextField
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme, styled } from '@mui/material/styles';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import * as gtag from "../../../../lib/google/gtag";
import * as send from '../../../../lib/analytics/send';
import { searchTicker } from "../../../utils/helpers";

const LISTBOX_PADDING = 8; // px
/* eslint-disable react/display-name */
function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle} sx={{ fontWeight: 600, color: 'text.color.white', py: 0, lineHeight: '40px', height: '40px !important', bgcolor: 'background.primary_light' }}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    dataSet[1]?.t &&
    <Tooltip title={dataSet[1]?.n1.length > 30 ? dataSet[1]?.n1 : ''} disableInteractive TransitionComponent={Zoom}>
      <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}
        sx={{
          fontSize: '.8rem', textTransform: 'uppercase', color: 'text.description',
          position: 'relative',
        }}
      >
        <Chip label={dataSet[1]?.t} size="small" sx={{ position: 'absolute', right: 0, fontSize: '.7rem', color: 'text.link', zIndex: 2, mx: 1, visibility: '' }} />
        <Typography variant="caption" sx={{ textOverflow: 'ellipsis', width: '85%', overflow: 'hidden' }}>
          {dataSet[1]?.n1}
        </Typography>
      </Typography>
    </Tooltip >
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);
  let noofGroups = itemData.filter(val => {
    return val.group
  })?.length

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const SearchTicker = ({ setShowSearch, removebackdrop, placeholder, dataEventFrom }) => {
  const [value, setValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [invalidTicker, setInvalidTicker] = React.useState(false);
  const router = useRouter()
  const loading = open && options.length === 0;

  const handleSearch = (event, search_term) => {
    router.push(`/stock/${search_term}`);
    send.uevent(event, `stock-search-${dataEventFrom} ${search_term}`);
    gtag.event({
      action: "ticker_search",
      search_term: search_term,
    });
  }

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (active) {
      (async () => {
        // let stockSearch_jsonData = require('../../../../public/assets/search/stock.json');
        // stockSearch_jsonData = stockSearch_jsonData.sort((a, b) => a.t?.toUpperCase().localeCompare(b.t?.toUpperCase()));
        let stockSearch_jsonData = await searchTicker({
          url: `/api/stock-search`,
          searchString: "",
        }) || [];
        setOptions([...stockSearch_jsonData]);
      })();
    }

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        bgcolor: 'rgba(255, 255, 255, 0.15)',
        borderRadius: '4px', position: "relative",
      }}
    >
      <Paper
        component="form"
        sx={{
          display: 'flex',
          alignItems: 'center',
          margin: '0 auto',
          bgcolor: 'transparent',
          zIndex: open ? (theme) => theme.zIndex.appBar : 0,
          height: '1.4375em',
          margin: '.5em 0 .481em 0',
          boxShadow: 0,
          '& .Mui-focused .MuiTextField-root': {
            width: { md: '49.4ch !important', xs: '28ch' },
          },
        }}
      >
        <IconButton type="submit" sx={{ p: '10px', position: 'absolute' }} aria-label="search" onClick={e => e.preventDefault()}>
          <SearchIcon sx={{ color: '#fff', fontSize: '1.2rem' }} />
        </IconButton>
        <Autocomplete type="search" data-event-name="Search" data-event-category={dataEventFrom} data-event-label="Stock Search"
          autoHighlight
          id="search-ticker"
          disableListWrap
          PopperComponent={StyledPopper}
          ListboxComponent={ListboxComponent}
          sx={{
            color: '#fff',
            transition: 'all ease-in-out .2s',
            fontSize: '.87rem',
            '& .MuiPaper-root-MuiAutocomplete-paper': {
              boxShadow: 0
            },
            '& .MuiAutocomplete-input': {
              color: '#fff'
            },
            '&.MuiAutocomplete-root .MuiFilledInput-root .MuiAutocomplete-endAdornment svg': {
              color: '#fff',
            },
            '&.MuiAutocomplete-root .MuiFilledInput-root.MuiInputBase-sizeSmall .MuiFilledInput-input': {
              padding: ' 2.5px 0 2.5px 32px',
              fontSize: '.9rem'
            }
          }}
          open={open}
          onOpen={() => { setOpen(true); }}
          onClose={() => { setOpen(false); setShowSearch(false) }}
          onChange={(event, newValue) => { setValue(newValue); router && newValue && newValue?.t ? handleSearch(event, newValue?.t) : event.preventDefault() }}
          onInputChange={(event, newInputValue) => {
            setValue(newInputValue);
            const available = options.filter(item => item?.n1?.toLowerCase().startsWith(newInputValue.trim().toLowerCase()) || item?.t?.toLowerCase().startsWith(newInputValue.trim().toLowerCase()))
            setInvalidTicker(!(available.length > 0) && newInputValue.trim() !== '');

          }}
          isOptionEqualToValue={(option, value) => option?.t === value?.t}
          options={options}
          loading={loading}
          filterOptions={(options, { inputValue }) => inputValue.trim() !== "" && options?.filter(item => item?.n1?.toLowerCase().startsWith(inputValue.trim().toLowerCase()) || item?.t?.toLowerCase().startsWith(inputValue.trim().toLowerCase())).sort((a, b) => a.t.length - b.t.length)}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return '';
          }}
          selectOnFocus
          clearOnBlur
          clearOnEscape
          handleHomeEndKeys
          freeSolo
          renderOption={(props, option) => [props, option]}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder ? placeholder : "Search Tickers..."}
              size="small"
              variant="filled"
              hiddenLabel
              sx={{
                width: { md: '35.4ch', xs: '28ch' },
                animationDuration: "10ms",
                transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                '& .MuiFilledInput-root': {
                  py: '8px !important'
                },
                '& .MuiFilledInput-root, .MuiFilledInput-root:hover, .MuiFilledInput-root:focus': {
                  background: 'transparent',
                },
                '&  .MuiFilledInput-root, .MuiFilledInput-root:hover, .MuiFilledInput-root:before, .MuiFilledInput-root:after': {
                  borderBottom: '0 !important',
                },
                '& .MuiInputBase-root-MuiFilledInput-root:before, .MuiInputBase-root-MuiFilledInput-root:hover:not(.Mui-disabled):before': {
                  borderBottom: '0 !important',
                },
                '& .MuiInputBase-input-MuiFilledInput-input': {
                  fontSize: '.87rem'
                },
              }}
            />
          )}
          renderGroup={(params) => params}
        />
      </Paper>
      {invalidTicker && (
        <Paper
          sx={{
            margin: '0 auto',
            bgcolor: '#fff',
            zIndex: open ? (theme) => theme.zIndex.appBar : 0,
            boxShadow: 4, position: "absolute", bottom: -52, left: 0, width: '100%'
          }}
        >
          <Typography variant="h6" sx={{ color: '#555', m: 1.7, fontSize: '.9rem' }}>
            {`We don’t cover this stock yet.`}
          </Typography>
        </Paper>
      )}
    </Box>
  );
}
export default (SearchTicker);
