import React, { useEffect, useState } from 'react'
import {
  // useStripe, useElements,
  Elements
} from "@stripe/react-stripe-js";
import {
  Box, Card, CardContent, Container, Grid, Typography, TextField, Divider, Link, Paper,
  // Alert, AlertTitle, TableHead, Avatar, Tabs, Tab, Radio, Button, Paper, Chip,
  // MenuItem, ListItem, ListItemText, List, ListItemButton
} from '@mui/material';
import {
  Table, TableBody, TableCell, TableContainer, TableRow,
} from '@mui/material';
// import EastIcon from '@mui/icons-material/East';
// import TypeWriter from './TypingCarousel';
// import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
// import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
// import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
// import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
// import ContactsRoundedIcon from '@mui/icons-material/ContactsRounded';
// import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
// import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
// import MuiAccordion from '@mui/material/Accordion';
// import MuiAccordionSummary from '@mui/material/AccordionSummary';
// import MuiAccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
// import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
// import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import PropTypes from 'prop-types'
import { dataConstants } from '../../constants';
// import { styled, withStyles } from '@mui/styles';
// import { isMobile } from '../../helpers/deviceDetect';
// import Link from 'next/link';
import SearchTicker from '../_common/SearchTicker';
import FAQs from './FAQs';
// import AppleStory from '../../static/images/page/home/AAPLnew1.svg';
// import TeslaStory from '../../static/images/page/home/TSLAnew.svg';
// import LandingFiveTwo from './LandingFiveTwo';
// import LandingPrice from './LandingPrice';
// import LandingFilings from './LandingFilings';
// import { NewspaperRounded } from '@mui/icons-material';
// import LandingFairValue from './LandingFairValue';
// import bg from '../../static/images/page/home/wave.png';
import bg from '../../../public/images/components/home/landingbg.png';
// import Waves from '../../static/images/page/home/waves.svg'
import applestock from '../../static/images/page/home/applestock.png'
import houingmarket from '../../static/images/page/home/houingmarket.png'
import berkshirefund from '../../static/images/page/home/berkshirefund.png'
import SearchFund from '../_common/SearchFund';
import { useRouter } from 'next/router';
import SsidChartRoundedIcon from '@mui/icons-material/SsidChartRounded';
import useAuth from '../../hook/auth';
import { loadStripe } from "@stripe/stripe-js";
import LandingCheckout from "./LandingCheckout";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

// import useScrollTrigger from '@mui/material/useScrollTrigger';
// import Fab from '@mui/material/Fab';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import Fade from '@mui/material/Fade';
import { getRandomStr } from "../../helpers";
import Image from 'next/image';
import * as send from '../../../lib/analytics/send';

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);

const SEARCH_TYPE = [
  {
    value: 'STOCK',
    label: 'Stocks',
  },
  {
    value: 'FUND',
    label: 'Funds',
  },
];


// const CouponCard = (props) => {
//   const { children, window } = props;
//   // Note that you normally won't need to set the window ref as useScrollTrigger
//   // will default to window.
//   // This is only being set here because the demo is in an iframe.
//   const trigger = useScrollTrigger({
//     target: window ? window() : undefined,
//     disableHysteresis: true,
//     threshold: 1800,
//   });

//   return (
//     <Fade in={trigger}>
//       <Box
//         role="presentation"
//         sx={{ position: 'fixed', bottom: 16, left: 16, }}
//       >
//         {children}
//       </Box>
//     </Fade>
//   );
// }

// const bgimage = {
//   backgroundImage: `url(${bg.src})`, backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', backgroundSize: "cover",
//   filter: 'blur(1px)'
// }

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? 'rgb(10, 25, 41)' : '#f2f2f2',
//   ...theme.typography.body2,
//   height: '100%',
//   color: theme.palette.text.secondary,
//   // boxShadow: theme.palette.mode === 'dark' ? 0 : '0 0.5px 1px rgb(132 135 154 / 20%), 0 2px 3px rgb(132 135 154 / 10%)',
//   // border: theme.palette.mode === 'dark' ? '1px solid rgb(19, 47, 76)' : 0,
//   padding: '8px 12px',
//   '& .Item-Body': {
//     paddingTop: 0,
//   }
// }));


// // Inspired by blueprintjs
// function StyledRadio(props) {
//   // const classes = useStyles();

//   return (
//     <Radio
//       sx={{
//         color: '#174c86',
//         '&:hover': {
//           backgroundColor: 'transparent',
//         },
//       }}
//       // className={classes.root}
//       disableRipple
//       // color="default"
//       checkedIcon={<span sx={{
//         backgroundColor: '#174c86',
//         backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
//         '&:before': {
//           display: 'block',
//           width: 16,
//           height: 16,
//           backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
//           content: '""',
//         },
//         'input:hover ~ &': {
//           backgroundColor: '#106ba3',
//         },
//       }}
//       // className={clsx(classes.icon, classes.checkedIcon)}
//       />}
//       icon={<span sx={{
//         borderRadius: '50%',
//         width: 16,
//         height: 16,
//         boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
//         backgroundColor: '#f5f8fa',
//         backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
//         '$root.Mui-focusVisible &': {
//           outline: '2px auto rgba(19,124,189,.6)',
//           outlineOffset: 2,
//           backgroundImage: '#0d6efd',
//         },
//         'input:hover ~ &': {
//           backgroundColor: '#ebf1f5',
//         },
//         'input:disabled ~ &': {
//           boxShadow: 'none',
//           background: 'rgba(206,217,224,.5)',
//         },
//       }}
//       // className={classes.icon}
//       />}
//       {...props}
//     />
//   );
// }

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// const StyledTabs = styled((props) => (
//   <Tabs
//     {...props}
//     TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
//   />
// ))({
//   '& .MuiTabs-indicator': {
//     display: 'flex',
//     justifyContent: 'center',
//     backgroundColor: 'transparent',
//   },
//   '& .MuiTabs-indicatorSpan': {
//     maxWidth: 40,
//     width: '100%',
//     backgroundColor: '#174c86',
//   },
// });

// const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
//   ({ theme }) => ({
//     textTransform: 'none',
//     fontWeight: theme.typography.fontWeightRegular,
//     fontSize: theme.typography.pxToRem(15),
//     marginRight: theme.spacing(1),
//     color: 'rgba(255, 255, 255, 0.7)',
//     '&.Mui-selected': {
//       color: '#fff',
//     },
//     '&.Mui-focusVisible': {
//       backgroundColor: 'rgba(100, 95, 228, 0.32)',
//     },
//   }),
// );

// const Accordion = styled(MuiAccordion)(({ theme }) => ({
//   boxShadow: 'none !important',
//   border: '1px solid #fff !important',
//   borderRadius: '7px !important',
//   '&:before': {
//     display: 'none !important',
//   },
//   '&.MuiPaper-root.Mui-expanded': {
//     borderRadius: '7px !important',
//     border: '1px solid #eae9ee !important',
//   },
// }));

// const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
//   // backgroundColor: '#fafafa !important',
//   backgroundColor: '#fff !important',
//   marginBottom: -1,
//   minHeight: 52,
//   '&.MuiAccordionSummary-root.Mui-expanded': {
//     minHeight: 52,
//     borderBottom: '1px solid #eae9ee !important',
//     // backgroundColor: 'rgba(0, 0, 0, .03) !important',
//     backgroundColor: '#FBFBFC !important',
//     borderRadius: '6px 6px 0 0 !important',
//   },
//   '& .MuiAccordionSummary-content.Mui-expanded': {
//     margin: '12px 0 !important',
//   },
// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//   padding: '16px !important',
// }));

const {
  // PLAN_MODES, PLAN_CYCLES,
  PLAN_QANDA, PLAN_COMPARISON,
  // PLAN_TYPES, PLANS
} = dataConstants || {};

const Landing = (props) => {
  const { pricePlans, pageLoading, } = props || {}
  const { user } = useAuth() || {}
  const router = useRouter()
  const { reloadUserInfo } = user || {}
  const isPremium = reloadUserInfo?.customAttributes && (JSON.parse(reloadUserInfo?.customAttributes)?.userType === "PREMIUM" || JSON.parse(reloadUserInfo?.customAttributes)?.userType === "PROFESSIONAL") ? true : false;

  // const staticText = ["Experience"]
  // const words = ['Real-time Data..', 'Reliable Data..', 'Powerful Data..',]
  const pricing_comparison_rows = PLAN_COMPARISON;
  // const plan = pricePlans && pricePlans.length > 0 ? pricePlans?.find(d => d.type === 'PREMIUM') : PLAN_QANDA;
  // const qAndA = plan && plan?.features ? JSON.parse(plan?.features).qAndA : PLAN_QANDA;
  // const [accordionExpanded, setAccordionExpanded] = useState('question0');
  const [showSearch, setShowSearch] = useState(false);
  const [searchType, setSearchType] = useState('STOCK');
  const [currency_type, set_currency_type] = useState('usd');

  useEffect(() => {
    const currentDate = new Date();
    if (`${currentDate}`.includes('India Standard Time')) {
      set_currency_type('inr')
    } else {
      set_currency_type('usd')
    }
  }, [new Date()]);

  const handleSearchType = (event) => {
    setSearchType(event.target.value);
  };
  // const handleChange = (event, newValue) => {
  //   setValue(newValue); PLAN_TYPES
  // };

  // const handleAccordion = (panel) => {
  //   setAccordionExpanded(panel);
  // };

  // const copyCoupon = () => {
  //   let cpnBtn = document.getElementById("cpnBtn");
  //   let cpnCode = document.getElementById("cpnCode");

  //   navigator.clipboard.writeText(cpnCode.innerHTML);
  //   cpnBtn.innerHTML = "COPIED";
  //   setTimeout(() => {
  //     cpnBtn.innerHTML = "COPY CODE";
  //   }, 1500);

  // }

  // const handleClick = event => {
  //   event.preventDefault();
  // };

  // const handleBillingChange = bc => {
  //   setPriceID(bc.target.value)
  //   setPlanCycle(bc.target.value);
  // };

  // const handleBillingCycle = bc => {
  //   setToggleBillingCycle(bc.target.value);
  // };

  // const appleRows = [
  //   { name: "Maestri Luca", designation: "Senior VIce President, CFO", buysell: "SOLD" },
  //   { name: "O'BRIEN DEIRDRE", designation: "Senior VIce President", buysell: "SOLD" },
  //   {
  //     name: "Capital Wealth Alliance, LLC", designation: "", buysell: "ADDED"
  //   },
  //   { name: "PRIVATE TRUST CO NA", designation: "", buysell: "NEW" },
  // ]

  // const teslaRows = [
  //   { name: "Kirkhorn Zachary", designation: "Chief Financial Officer", buysell: "SOLD" },
  //   {
  //     name: "Taneja Vaibhav", designation: "Chief Accounting Officer", buysell: "ACQUIRED"
  //   },
  //   { name: "Capital Wealth Alliance, LLC", designation: "", buysell: "ADDED" },
  //   { name: "Coston, McIsaac & Partners", designation: "", buysell: "NEW" },
  // ]

  // if (typeof document != "undefined" && typeof window != "undefined") {
  //   const stockBGImage = document.getElementsByClassName('stock-bg-image');
  //   document.addEventListener('scroll', () => {
  //     let scroll = window?.pageYOffset;
  //     stockBGImage?.style?.filter = 'blur(' + scroll / 50 + 'px)';
  //   })
  // }
  const popularFunds = [
    { fcik: '1067983', fundName: 'Warren Buffett' },
    { fcik: '1697748', fundName: 'Cathie Wood' },
    { fcik: '1350694', fundName: 'Ray Dalio' },
    { fcik: '1029160', fundName: 'George Soros' }
  ]

  return (
    <Box component="div" sx={{ overflow: 'hidden', '& .stock-bg': { filter: 'blur(0px)' } }}>
      <Container maxWidth="xxl" sx={{
        height: '91vh', position: 'relative', textAlign: 'center', overflow: 'auto',
        // boxShadow: 'inset 0px -8px 24px rgba(214, 227, 255, 0.26)',
        background: `url(/images/components/home/cover-back.svg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '& img': {
          '-webkit-filter': 'grayscale(1) invert(1)',
          filter: 'grayscale(1) invert(1)', pointerEvents: 'none'
        }
      }}>
        {/* <Box component="div" className="stock-bg-image"
          style={{
            backgroundImage: `url(${bg.src})`, backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', backgroundSize: "cover",
            filter: 'blur(1px)'
          }} sx={{ height: '100%', widht: '100wv' }} /> */}
        {/* <Image
          className="stock-bg"
          src={bg.src}
          // src={`/images/components/home/landingbg.jpg`}
          alt="bgimage"
          layout="fill" // Or other desired layout
          objectFit="cover" // Adjust as needed
          objectPosition="center" // Adjust as needed
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', }}
        /> */}
        <Box component="div"
          sx={{
            position: 'absolute',
            top: '43%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 2,
            textAlign: 'center',
            width: { md: 'auto', sm: 'auto', xs: '88%' },
          }}>
          <Typography variant="h2" component="div" sx={{
            fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
            fontWeight: 600,
            lineHeight: '1.2222222222222223',
            color: '#132F4C',
            marginTop: '10px',
            maxWidth: { md: '630px', sm: '100%', xs: '100%' },
            mb: '2.5rem',
          }}
          >
            Discover The Joy of&nbsp;
            <Typography sx={{ fontWeight: 'inherit', fontSize: 'inherit', whiteSpace: 'no-wrap', color: '#2DA1EE', background: 'linear-gradient(to right, #2DA1EE, #174c86)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent', }}>
              Investing...
            </Typography>
          </Typography>
          {/* <Typography component="p"
            sx={{ color: 'text.description', mb: '2.5rem', fontSize: '.875rem', mt: 1.5 }}>
            {`Explore, screen, & track your portfolio with ease. Invest smarter, not harder.`}
          </Typography> */}
          {/* <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> */}
          <Paper sx={{ display: 'flex', alignItems: 'center', boxShadow: '0px 8px 20px rgba(0,0,0,0.06)', p: .5, borderRadius: '8px' }} >
            <Box component="div"
              sx={{
                background: '#fff', width: { md: '80%', sm: '80%', xs: '73%' }, borderRadius: '8px 0 0 8px', borderRight: 0,
                // border: '1px solid #eae9ee', // border: '1px solid #2a2d6470',
                '& svg': { color: 'hsl(235deg 59.62% 21.27%)' /*#020B4A*/ },
                '& input::placeholder': { color: 'hsl(235deg 59.62% 21.27%)' /*#020B4A*/ },
                '& input': { color: 'hsl(235deg 59.62% 21.27%)' /*#020B4A*/ },
                '& .MuiAutocomplete-input': { color: "hsl(235deg 59.62% 21.27%) !important" }, /*#020B4A*/
                '& .MuiFilledInput-root .MuiAutocomplete-endAdornment svg': { color: "hsl(235deg 59.62% 21.27%) !important" }, /*#020B4A*/
                '& .MuiAutocomplete-root .MuiFilledInput-root': { paddingLeft: '40px', background: 'transparent' },
                "& .MuiAutocomplete-root .MuiFilledInput-root.MuiInputBase-sizeSmall .MuiFilledInput-input ": { padding: '0 !important', background: 'transparent' },
              }}>
              {searchType === SEARCH_TYPE[0].value ?
                <SearchTicker
                  setShowSearch={setShowSearch}
                  removebackdrop={true}
                  placeholder="Ticker symbol, company name, keyword..."
                  dataEventFrom={"homepage"}
                />
                :
                <SearchFund
                  setShowSearch={setShowSearch}
                  removebackdrop={true}
                  placeholder="Search funds by name (e.g., berkshire)..."
                  dataEventFrom={"homepage"}
                />}
            </Box>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <TextField
              // sx={{ background: 'linear-gradient(to right bottom, rgb(17 95 189), rgb(2 14 74) 120%)',}}
              sx={{
                width: { md: '20%', sm: '20%', xs: '27%' },
                '& svg': { color: 'text.description' },
                '& .MuiFilledInput-input': {
                  padding: { md: '10px 30px 9.5px 17px !important', xs: '10px 30px 9.5px 3px !important' }, fontSize: '.9rem', borderRadius: '0 8px 8px 0 !important',
                  // background: 'rgba(255, 255, 255, 0.15)',
                  color: 'text.description'
                },
                '& .MuiFilledInput-root.Mui-focused, .MuiFilledInput-root:hover, .MuiInputBase-input.MuiFilledInput-input:focus': { backgroundColor: '#fff' /* #020B4A */, borderBottom: '0 important', },
                '& .MuiInputBase-root': {
                  borderRadius: '0 8px 8px 0 !important',
                  // border: '1px solid #020B4A',
                  background: '#fff', /* #2a2d64 */
                },
                '& .MuiFilledInput-root:before, .MuiFilledInput-root:after': { borderBottom: '0 !important' },
              }}
              id="outlined-select-search"
              select
              variant="filled"
              value={searchType}
              onChange={handleSearchType}
              SelectProps={{
                native: true,
                Icon: (<ExpandMoreRoundedIcon />),
              }}
            // helperText="Please select your type "
            >
              {SEARCH_TYPE.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Paper>
          {/* </Box> */}
          <Box>
            <Typography component='div' variant="caption" color="#545454" sx={{ fontSize: '.8rem', mt: '2.4rem' }}>
              Popular Tickers:&nbsp;
              {['AAPL', 'TSLA', 'AMZN', 'GOOG', 'MSFT', 'NVDA'].map((d, i) =>
                <Typography key={getRandomStr(3)} variant="caption" sx={{ fontSize: '.8rem', '& a': { color: 'text.link', } }}>
                  &nbsp;
                  <Link
                    underline="hover"
                    href={`/stock/${d}`}
                    onClick={(e) => send.uevent(e)}
                    data-event-name="href"
                    data-event-category="homepage"
                    data-event-label={`/stock/${d}`}
                  >
                    {i <= 4 ? `${d},` : `${d}`}
                  </Link>
                </Typography>
              )}
            </Typography>
            <Typography component='div' variant="caption" color="#545454" sx={{ fontSize: '.8rem', mt: 2 }}>
              Popular Funds:&nbsp;
              {popularFunds.map((d, i) =>
                <Typography key={getRandomStr(3)} variant="caption" sx={{ fontSize: '.8rem', '& a': { color: 'text.link', } }}>
                  &nbsp;
                  <Link
                    underline="hover"
                    href={`/funds/${d.fcik}`}
                    onClick={(e) => send.uevent(e)}
                    data-event-name="href"
                    data-event-category="homepage"
                    data-event-label={`/funds/${d.fcik}`}
                  >
                    {i <= 2 ? `${d.fundName},` : `${d.fundName}`}
                  </Link>
                </Typography>
              )}
            </Typography>
          </Box>
          {/* <Box
              sx={{
                position: 'relative',
                width: '100vw',
                height: '15vh',
                marginBottom: '-7px',
                minHeight: '100px',
                maxHeight: '150px',
              }}>
              <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                <defs>
                  <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g className="parallax">
                  <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(2,14,74,0.7" />
                  <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(2,14,74,0.5)" />
                  <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(2,14,74,0.3)" />
                  <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                </g>
              </svg>
            </Box> */}
        </Box>
      </Container>
      <Container maxWidth="xl" sx={{ position: 'relative' }}>
        <Container maxWidth="lg"
          sx={{
            height: { sm: '100%', xs: '100%', md: '1000px', lg: '800px' },
            // p: { md: '0 !important' }, '& .MuiGrid-root': { p: { md: '0 !important' } }
          }} >
          <Grid container spacing={2} sx={{
            '& .inverted-border-radius:before': {
              content: '""',
              position: 'absolute',
              backgroundColor: 'transparent',
              top: '30px',
              right: '0px',
              height: '50px',
              width: '25px',
              borderTopRightRadius: '25px',
              boxShadow: '0 -25px 0 0 #e3e8ee',
            },
          }}>
            <Grid item md={6}
              sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',

              }}>
              <Box>
                <Box>
                  <Typography variant="h1" gutterBottom component="div"
                    sx={{
                      fontWeight: '600', fontSize: 'clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem)', mb: 0,
                      // color: '#2DA1EE',
                      // background: 'linear-gradient(to right, #2DA1EE, #174c86)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent',
                    }}
                  >
                    Experience
                    <Typography component="div" sx={{ fontWeight: 'inherit', fontSize: 'inherit', whiteSpace: 'no-wrap', color: '#2DA1EE', background: 'linear-gradient(to right, #2DA1EE, #174c86)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent', }}>
                      Powerful Data...
                    </Typography>
                  </Typography>
                  {/* <TypeWriter staticText={staticText} words={words} /> */}
                  <Typography variant="body1" gutterBottom component="div"
                    sx={{
                      color: 'text.description', lineHeight: '2',
                      color: 'text.description', fontSize: '.9rem', mr: 4
                    }}>
                    {`"Grufity doesn't just provide you point-in-time metrics for stocks like most websites on internet, it also provides the historical trend of such metrics. Knowing the history of price to sales ratio of a stock is more important than knowing it's point value now as the history puts the current value in right context."`}
                  </Typography>
                </Box>
                {/* <Box sx={{ mt: 5 }}>
                  <Button variant="contained"
                    //  endIcon={<EastIcon />}
                    href="/auth"
                    sx={{ fontSize: '.9rem', bgcolor: '#174c86', '&:hover': { bgcolor: '#174c86', '& .MuiButton-endIcon': { ml: 3, transition: 'all 0.2s ease-in-out' } } }}>Sign up</Button>
                </Box> */}
              </Box>
            </Grid>
            <Grid item md={6} sx={{ bgcolor: '174c861a', maxHeight: '750px', display: 'initial' }} onClick={() => setShowSearch(false)}>
              {/* <Box component="div"
                sx={{
                  // boxShadow: 20,
                  mt: 2,
                  backgroundColor: '#174c861a',
                  // minWidth: '1000px',
                  minHeight: '500px',
                  maxHeight: '750px',
                  height: 'calc(100vh - 100px)',
                  borderRadius: '10px',
                  '-webkit-transition': 'max-height 0.3s',
                  transition: 'max-height 0.3s',
                  position: 'relative',
                  overflow: 'hidden',
                  padding: '30px',
                  marginLeft: '20px',
                }}
              >
                <Grid container spacing={2}
                  sx={{
                    // '& .MuiBox-root': { p: '0 !important' }
                  }}>
                  <Grid item md={6} sx={{ minWidth: '400px' }}>
                    <TabPanel value={value} index={0}>
                      <Box component="div" className="stock-chart" sx={{ '& svg': { borderRadius: '15px' }, bgcolor: 'transparent', mb: 2 }}>
                        <AppleStory />
                      </Box>
                      <Box component="div" sx={{ mb: 2.5 }}>
                        <LandingFiveTwo
                          yearHighLow={{
                            year_low: '128.86',
                            year_high: '182.19',
                            year_now: '142.48',
                            ticker: "AAPL"
                          }}
                        />
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box component="div" className="stock-chart" sx={{ '& svg': { borderRadius: '15px' }, bgcolor: 'transparent', mb: 2 }}>
                        <TeslaStory />
                      </Box>
                      <Box component="div" sx={{ mb: 2.5 }}>
                        <LandingFiveTwo
                          yearHighLow={{
                            year_low: '206.86',
                            year_high: '414.50',
                            year_now: '268.21',
                            ticker: "TSLA"
                          }}
                        />
                      </Box>
                    </TabPanel>
                    <Box sx={{ background: 'linear-gradient(to right bottom, rgb(0 61 152), rgb(2 14 74) 120%)', borderRadius: '10px', boxShadow: 15, '& .Mui-disabled': { color: 'rgba(255, 255, 255, 0.7) !important' } }}>
                      <StyledTabs
                        value={value}
                        onChange={handleChange}
                        aria-label="styled tabs example"
                      >
                        <StyledTab label="Apple Inc" />
                        <StyledTab label="Tesla Inc" />
                        <StyledTab disabled label="and 5000+ US stocks..." />
                      </StyledTabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                      <LandingFilings
                        title="Quarterly Report of Apple Inc"
                        publisher="SEC filing 10-Q"
                        date="Jul 29, 2022"
                        news_title="Dow Jones Down Over 400 Points As Apple Stock Tanks; Meta Warns Of Downsizing."
                        news_publisher="Investor's Business Daily"
                        news_date="20 hours ago"
                      />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <LandingFilings
                        title="1 Major Insider Sales Recently"
                        publisher="SEC filing 4"
                        date="Jul 29, 2022"
                        news_title="Tesla (TSLA) adds Airbnb co-founder to its board."
                        news_publisher="Electrek.co"
                        news_date="2 hours ago"
                      />
                    </TabPanel>
                  </Grid>
                  <Grid item md={6} sx={{ '& .MuiBox-root': { py: '0 !important' } }}>
                    <TabPanel value={value} index={0}>
                      <LandingPrice
                        percent="-0.24"
                        price="142.09"
                        diff="-1"
                        diffNum="-0.28%"
                      />
                      <LandingFairValue
                        defaultValue={30}
                      />
                      <Item className="crisischart" sx={{ mt: 4 }} elevation={0}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }} className="Item-Body">
                          <Typography variant="h6" sx={{ fontSize: '.89rem', fontWeight: 600 }}>
                            EBT Margin
                          </Typography>
                          <Typography variant="p" sx={{ fontWeight: 600 }}>
                            30.37%
                          </Typography>
                        </Box>
                      </Item>
                      <Item className="crisischart" sx={{ my: 4 }} elevation={0}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }} className="Item-Body">
                          <Typography variant="h6" sx={{ fontSize: '.89rem', fontWeight: 600 }}>
                            Market Cap
                          </Typography>
                          <Typography variant="p" sx={{ fontWeight: 600 }}>
                            2.4T
                          </Typography>
                        </Box>
                      </Item>
                      <TableContainer component={Paper} elevation={0} sx={{ width: 350, bgcolor: '#f2f2f2' }}>
                        <Table sx={{}} size="small" aria-label="a dense table">
                          <TableHead sx={{ background: '#e3e8ee' }}>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                              <TableCell align="right" sx={{ fontWeight: 600 }}>Buy/Sell</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody sx={{}}>
                            <TableRow>
                              <TableCell colSpan={2} sx={{ background: '#fff', color: '#174c86', fontWeight: 600 }}>Latest Insider Trading transactions</TableCell>
                            </TableRow>
                            {appleRows.slice(0, 2).map((row) => (
                              <TableRow
                                key={row?.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  <Typography variant="p" sx={{ fontWeight: 600 }}>
                                    {row?.name}
                                  </Typography>
                                  <Typography component="div" variant="caption" sx={{ fontSize: '.7rem', color: "text.description" }}>
                                    {row?.designation}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right" sx={{ color: row?.buysell === "SOLD" ? 'red' : 'green' }}>{row?.buysell}</TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell colSpan={2} sx={{ background: '#fff', color: '#174c86', fontWeight: 600 }}>Which funds bought or sold Apple</TableCell>
                            </TableRow>
                            {appleRows.slice(2, 4).map((row) => (
                              <TableRow
                                key={row?.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  <Typography variant="p" sx={{ fontWeight: 600 }}>
                                    {row?.name}
                                  </Typography>
                                  <Typography component="div" variant="caption" sx={{ fontSize: '.7rem', color: "text.description" }}>
                                    {row?.designation}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right" sx={{ color: row?.buysell === "SOLD" ? 'red' : 'green' }}>{row?.buysell}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <LandingPrice
                        percent="+4.61"
                        price="272.01"
                        diff="1"
                        diffNum="+1.72%"
                      />
                      <LandingFairValue
                        defaultValue={67}
                      />
                      <Item className="crisischart" sx={{ mt: 4 }} elevation={0}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }} className="Item-Body">
                          <Typography variant="h6" sx={{ fontSize: '.89rem', fontWeight: 600 }}>
                            EBT Margin
                          </Typography>
                          <Typography variant="p" sx={{ fontWeight: 600 }}>
                            15.17%
                          </Typography>
                        </Box>
                      </Item>
                      <Item className="crisischart" sx={{ my: 4 }} elevation={0}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }} className="Item-Body">
                          <Typography variant="h6" sx={{ fontSize: '.89rem', fontWeight: 600 }}>
                            Market Cap
                          </Typography>
                          <Typography variant="p" sx={{ fontWeight: 600 }}>
                            285.2B
                          </Typography>
                        </Box>
                      </Item>
                      <TableContainer component={Paper} elevation={0} sx={{ width: 350, bgcolor: '#f2f2f2' }}>
                        <Table sx={{}} size="small" aria-label="a dense table">
                          <TableHead sx={{ background: '#e3e8ee' }}>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                              <TableCell align="right" sx={{ fontWeight: 600 }}>Buy/Sell</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody sx={{}}>
                            <TableRow>
                              <TableCell colSpan={2} sx={{ background: '#fff', color: '#174c86', fontWeight: 600 }}>Latest Insider Trading transactions</TableCell>
                            </TableRow>
                            {teslaRows.slice(0, 2).map((row) => (
                              <TableRow
                                key={row?.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  <Typography variant="p" sx={{ fontWeight: 600 }}>
                                    {row?.name}
                                  </Typography>
                                  <Typography component="div" variant="caption" sx={{ fontSize: '.7rem', color: "text.description" }}>
                                    {row?.designation}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right" sx={{ color: row?.buysell === "SOLD" ? 'red' : 'green' }}>{row?.buysell}</TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell colSpan={2} sx={{ background: '#fff', color: '#174c86', fontWeight: 600 }}>Which funds bought or sold Apple</TableCell>
                            </TableRow>
                            {teslaRows.slice(2, 4).map((row) => (
                              <TableRow
                                key={row?.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  <Typography variant="p" sx={{ fontWeight: 600 }}>
                                    {row?.name}
                                  </Typography>
                                  <Typography component="div" variant="caption" sx={{ fontSize: '.7rem', color: "text.description" }}>
                                    {row?.designation}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right" sx={{ color: row?.buysell === "SOLD" ? 'red' : 'green' }}>{row?.buysell}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </Grid>
                </Grid>
              </Box> */}
              <Box
                sx={{
                  mt: 2,
                  // backgroundColor: '#174c861a',
                  backgroundColor: '#e5e7fb',
                  // minHeight: '500px',
                  maxHeight: '750px',
                  height: 'calc(100vh - 100px)',
                  borderRadius: '10px',
                  '-webkit-transition': 'max-height 0.3s',
                  transition: 'max-height 0.3s',
                  position: 'relative',
                  overflow: 'hidden',
                  marginLeft: '20px',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Box component="div"
                  sx={{
                    padding: '30px',
                    opacity: 1,
                    transition: 'opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    '&:hover .houingmarket': {
                      width: '300px !important',
                      top: '30px !important',
                      transform: 'translate(-80%) !important',
                    },
                    '&:hover .berkshirefund': {
                      width: '300px !important',
                      top: '90px !important',
                      transform: 'translate(-20%) !important',
                    },
                    '&:hover .applestock': {
                      width: '300px !important',
                      top: '260px !important',
                      transform: 'translate(-60%) !important',
                    },
                    // '& .berkshirefund:hover': {
                    //   zIndex: 2
                    // }
                  }}>
                  <img
                    src={houingmarket.src}
                    alt={"houingmarket"}
                    className="houingmarket"
                    // layout="responsive"
                    // quality={100}
                    // height={60}
                    // width={100}
                    // blurDataURL="URL"
                    // placeholder="blur"
                    style={{
                      filter: 'drop-shadow(rgba(61, 71, 82, 0.2) -8px 4px 20px)',
                      transition: 'all 0.4s ease 0s',
                      display: 'block',
                      height: 'auto',
                      borderRadius: '10px',
                      width: '350px',
                      // height: '80%',
                      top: '120px',
                      transform: 'translate(-70%)',
                      position: 'absolute',
                      left: '50%'
                    }}
                  />
                  <img
                    src={berkshirefund.src}
                    alt={"berkshirefund"}
                    className="berkshirefund"
                    // layout="responsive"
                    // quality={100}
                    // height={60}
                    // width={100}
                    // blurDataURL="URL"
                    // placeholder="blur"
                    style={{
                      filter: 'drop-shadow(rgba(61, 71, 82, 0.2) -8px 4px 20px)',
                      transition: 'all 0.4s ease 0s',
                      display: 'block',
                      height: 'auto',
                      borderRadius: '10px',
                      width: '350px',
                      // height: '80%',
                      top: '80px',
                      transform: 'translate(-50%)',
                      position: 'absolute',
                      left: '50%'
                    }}
                  />
                  <img
                    src={applestock.src}
                    alt={"applestock"}
                    className="applestock"
                    // layout="responsive"
                    // quality={100}
                    // height={60}
                    // width={100}
                    // blurDataURL="URL"
                    // placeholder="blur"
                    style={{
                      filter: 'drop-shadow(rgba(61, 71, 82, 0.2) -8px 4px 20px)',
                      transition: 'all 0.4s ease 0s',
                      display: 'block',
                      height: 'auto',
                      borderRadius: '10px',
                      width: '350px',
                      // height: '80%',
                      top: '40px',
                      transform: 'translate(-30%)',
                      position: 'absolute',
                      left: '50%'
                    }}
                  />
                </Box>
                <Box sx={{ bgcolor: 'primary.main', zIndex: 3, padding: 3, }}>
                  <Typography variant="body1" sx={{ color: '#fff', fontWeight: '600', }}>
                    {`"...eliminates the gap between hedge funds and retail investors."`}
                  </Typography>
                  {/* <Typography component="div" variant="caption" sx={{ color: '#fff', fontWeight: '500', mt: 2 }}>
                    There are two main reasons why a hedge fund manager seem to have an edge over regular investor - Information and Time.
                  </Typography> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        {/* <Container maxWidth="lg" sx={{ my: '5rem' }}>
          <Box component="div">
            <Typography variant="body1" sx={{ fontWeight: 700, color: '#174c86', }}>Funds Buys And Sales Is A Great Way To Explore The Universe Of Stocks.</Typography>
            <Typography variant="h2" sx={{
              fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
              fontWeight: 800,
              lineHeight: '1.2222222222222223',
              color: '#132F4C',
              marginTop: '10px',
              maxWidth: '630px',
            }}
            >  "...hop on hop off through various funds to&nbsp;
              <Typography sx={{ fontWeight: 'inherit', fontSize: 'inherit', whiteSpace: 'no-wrap', color: '#2DA1EE', background: 'linear-gradient(to right, #2DA1EE, #174c86)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent', }}>
                explore new stocks
              </Typography>"
            </Typography>
          </Box>
          <Grid container spacing={2} sx={{ mt: 4 }}>
            <Grid item md={4}>
              <Card variant="outlined" elevation={0} sx={{ height: '100%', borderRadius: '15px', bgcolor: '#fff', '& a': { cursor: 'pointer', fontSize: '.8rem', textDecoration: 'none', color: '#174c86' }, '& a:hover': { textDecoration: 'underline' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', }} gutterBottom>
                    <QueryStatsRoundedIcon sx={{ fontSize: '1.2rem', color: '#174c86', mr: 1, mb: .5 }} />
                    <Link href="/stock">
                      <Box component="div" sx={{ display: 'flex', alignItems: 'center', '&:hover .list-icon': { ml: 1.5, transition: 'all 0.2s ease-in-out' } }} >
                        <Typography sx={{ fontSize: 14, color: '#132F4C', fontWeight: 600 }} color="text.very_light_desc" >
                          Stock Discovery
                        </Typography>
                        <ChevronRightRoundedIcon className="list-icon" sx={{ fontSize: '1.2rem', color: '#132F4C', }} />
                      </Box>
                    </Link>
                  </Box>
                  <Typography component="div" sx={{ mt: 1.5, fontSize: '.8rem' }} color="text.very_light_desc">
                    What other fund managers buy or sell is a great way to discover new stocks. Take the case of Tesla. Cathie Woods one of the earliest vocal bullish investor in Tesla began to add Tesla to her fund&nbsp;
                    <a href="/funds/1697748">ARK Investments</a> in 2017-18 (Tesla stock price: ~55-75). A quick access to her entire portfolio would have been very useful information for you.

                    Following Cathie Woods on Grufity would have exposed you to so many other high performing stocks such as
                    &nbsp;<a href="stock/SQ">Square (SQ)</a>, <a href="/stock/ROKU">Roku (Roku)</a>, <a href="/stock/TDOC">Teladoc (TDOC)</a> etc.
                    <br />Look at what she is buying today. (<a href="/funds/1697748">ARK Investments</a>)
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card variant="outlined" elevation={0} sx={{ height: '100%', borderRadius: '15px', bgcolor: '#fff', '& a': { cursor: 'pointer', fontSize: '.8rem', textDecoration: 'none', color: '#174c86' }, '& a:hover': { textDecoration: 'underline' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', }} gutterBottom>
                    <PeopleOutlineRoundedIcon sx={{ fontSize: '1.2rem', color: '#174c86', mr: 1 }} />
                    <Link href="/">
                      <Typography sx={{ fontSize: 14, color: '#132F4C', fontWeight: 600 }} color="text.very_light_desc" >
                        Shadow Investing
                      </Typography>
                    </Link>
                    <Box component="div" sx={{ display: 'flex', alignItems: 'center', '&:hover .list-icon': { ml: 1.5, transition: 'all 0.2s ease-in-out' } }} >
                      <ChevronRightRoundedIcon className="list-icon" sx={{ fontSize: '1.2rem', color: '#132F4C', }} />
                    </Box>
                  </Box>
                  <Typography component="div" sx={{ mt: 1.5, fontSize: '.8rem' }} color="text.very_light_desc">
                    <Typography sx={{ color: '#174c86', fontSize: 'inherit' }}>
                      Income Statement, Balance Sheet, Cashflow,&nbsp;
                    </Typography>
                    All fund managers follow portfolios of other popular fund managers. For example, several fund managers just shadow invest with <a href="/funds/1067983">Warren Buffett</a>.
                    <br /><br />
                    They allocate a certain percentage of their portfolio that just replicates <a href="/funds/1067983">Buffett's holdings</a>.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card variant="outlined" elevation={0} sx={{ height: '100%', borderRadius: '15px', bgcolor: '#fff', '& a': { cursor: 'pointer', fontSize: '.8rem', textDecoration: 'none', color: '#174c86' }, '& a:hover': { textDecoration: 'underline' } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', }} gutterBottom>
                    <ContactsRoundedIcon sx={{ fontSize: '1.2rem', color: '#174c86', mr: 1 }} />
                    <Link href="/">
                      <Box component="div" sx={{ display: 'flex', alignItems: 'center', '&:hover .list-icon': { ml: 1.5, transition: 'all 0.2s ease-in-out' } }} >
                        <Typography sx={{ fontSize: 14, color: '#132F4C', fontWeight: 600 }} color="text.very_light_desc" >
                          Portfolio Construction
                        </Typography>
                        <ChevronRightRoundedIcon className="list-icon" sx={{ fontSize: '1.2rem', color: '#132F4C', }} />
                      </Box>
                    </Link>
                  </Box>
                  <Typography component="div" sx={{ mt: 1.5, fontSize: '.8rem' }} color="text.very_light_desc">
                    <Typography sx={{ color: '#174c86', fontSize: 'inherit' }}>
                      Yield Curve:&nbsp;
                    </Typography>
                    Studying other fund managers gives pointers on how to construct your own portfolio. For example, Everyone knows Buffett owns Apple (<a href="/stock/AAPL">AAPL</a>). But did you know that it makes a massive ~42% of his portfolio?
                    <br /><br />
                    It will give you new perspective on diversification and how many stocks one should have in portfolio.
                  </Typography>
                </CardContent>
              </Card >
            </Grid >
          </Grid >
        </Container > */}
        {/* <Container maxWidth="lg" sx={{ my: '6rem', height: { sm: '100%', xs: '100%', md: '50vh' } }}>
          <Box component="div">
            <Typography variant="body1" sx={{ fontWeight: 700, color: '#174c86', }}>Exploring investing opprtunities at fast speed</Typography>
            <Typography variant="h2" sx={{
              fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
              fontWeight: 800,
              lineHeight: '1.2222222222222223',
              color: '#132F4C',
              marginTop: '10px',
              maxWidth: '630px',
            }}
            >  "...eliminates the gap between&nbsp;
              <Typography sx={{ fontWeight: 'inherit', fontSize: 'inherit', whiteSpace: 'no-wrap', color: '#2DA1EE', background: 'linear-gradient(to right, #2DA1EE, #174c86)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent', }}>
                hedge funds and retail investors
              </Typography>"
            </Typography>
          </Box>
        </Container> */}
      </Container >
      <Container maxWidth="xxl" sx={{ px: '0 !important', py: '7rem', bgcolor: '#fff', backgroundImage: 'linear-gradient(0deg, transparent, rgb(227 230 251), transparent)' }}>
        <Container maxWidth="lg">
          <Box component="div">
            <Typography variant="body1" sx={{ fontWeight: 700, color: '#174c86', }}>Easy Access</Typography>
            <Typography variant="h2" sx={{
              fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
              fontWeight: 800,
              lineHeight: '1.2222222222222223',
              color: '#132F4C',
              marginTop: '10px',
              maxWidth: '600px',
            }}
            >  All the&nbsp;
              <Typography sx={{ fontWeight: 'inherit', fontSize: 'inherit', whiteSpace: 'no-wrap', color: '#2DA1EE', background: 'linear-gradient(to right, #2DA1EE, #174c86)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent', }}>
                financial data and tools
              </Typography>
              &nbsp;you need, at one place
            </Typography>
          </Box>
          <Grid container spacing={2} sx={{ mt: 4 }}>
            <Grid item md={4}>
              <Card variant="outlineda" elevation={0} sx={{ borderRadius: '15px', bgcolor: '#fafafaa', height: '100%' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', '& a': { cursor: 'pointer' } }} gutterBottom>
                    <SsidChartRoundedIcon sx={{ fontSize: '1.2rem', color: '#174c86', mr: 1, mb: .5 }} />
                    <Typography sx={{ fontSize: 14, color: '#132F4C', fontWeight: 600 }} color="text.very_light_desc" >
                      Stocks
                    </Typography>
                  </Box>
                  <Typography component="div" sx={{ mt: 1.5, fontSize: '.8rem', '& a': { textDecoration: 'none', color: 'text.link' }, '& a:hover': { textDecoration: 'underline' } }} color="text.very_light_desc">
                    {/* <Typography sx={{ color: '#174c86', fontSize: 'inherit' }}>
                      Changes in Investor Positions:&nbsp;
                    </Typography> */}
                    The best place on internet to do your&nbsp;
                    <Link href="/stock/AAPL">due-diligence</Link>
                    &nbsp;on any stock. Detail Historical trend on Financial ratios (PE, PS, ROE, ROA, Debt/Equity ratio, Financial Health metrics, operation health metrics. etc).
                  </Typography>
                  <Typography component="div" sx={{ mt: 1.5, fontSize: '.8rem' }} color="text.very_light_desc">
                    {/* <Typography sx={{ color: '#174c86', fontSize: 'inherit' }}>
                      Institutional Ownership:&nbsp;
                    </Typography> */}
                    Insider trading trends and alets.<br /> Are funds buying or selling the stock? <br />Latest financial reports and news.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card variant="outlineda" elevation={0} sx={{ borderRadius: '15px', bgcolor: '#fafafaa', height: '100%' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', '& a': { cursor: 'pointer' } }} gutterBottom>
                    <PaidRoundedIcon sx={{ fontSize: '1.2rem', color: '#174c86', mr: 1 }} />
                    <Typography sx={{ fontSize: 14, color: '#132F4C', fontWeight: 600 }} color="text.very_light_desc" >
                      Funds
                    </Typography>
                  </Box>
                  <Typography component="div" sx={{ mt: 1.5, fontSize: '.8rem' }} color="text.very_light_desc">
                    {/* <Typography sx={{ color: '#174c86', fontSize: 'inherit' }}>
                      Income Statement, Balance Sheet, Cashflow,&nbsp;
                    </Typography> */}
                    A great tool for shadow investing. Coverage of all major funds.
                    <br />
                    <br />
                    {`What are their current holdings? What stocks they bought or sold recently. Percentage increase in their positon sizes. Historical trend of how a fund increased or decreased it's position in a particular stock.`}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card variant="outlineda" elevation={0} sx={{ borderRadius: '15px', bgcolor: '#fafafaa', height: '100%' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', '& a': { cursor: 'pointer' } }} gutterBottom>
                    <InsertChartRoundedIcon sx={{ fontSize: '1.2rem', color: '#174c86', mr: 1 }} />
                    <Typography sx={{ fontSize: 14, color: '#132F4C', fontWeight: 600 }} color="text.very_light_desc" >
                      Economy
                    </Typography>
                  </Box>
                  <Typography component="div" sx={{ mt: 1.5, fontSize: '.8rem' }} color="text.very_light_desc">
                    <Typography sx={{ color: '#174c86', fontSize: 'inherit' }}>
                      Yield Curve:&nbsp;
                    </Typography>
                    The shape of the yield curve has been a strong predictor of recessions in the past. Visit our daily updated Yield Curve analysis.
                  </Typography>
                  <Typography component="div" sx={{ mt: .5, fontSize: '.8rem' }} color="text.very_light_desc">
                    <Typography sx={{ color: '#174c86', fontSize: 'inherit' }}>
                      Inflation:&nbsp;
                    </Typography>
                    Our Inflation page provides quick view of CPI, PCE etc.
                  </Typography>
                  <Typography component="div" sx={{ mt: .5, fontSize: '.8rem' }} color="text.very_light_desc">
                    <Typography sx={{ color: '#174c86', fontSize: 'inherit' }}>
                      GDP:&nbsp;
                    </Typography>
                    Breakdown of GDP and past trends.
                  </Typography>
                  <Typography component="div" sx={{ mt: .5, fontSize: '.8rem' }} color="text.very_light_desc">
                    <Typography sx={{ color: '#174c86', fontSize: 'inherit' }}>
                      Housing:&nbsp;
                    </Typography>
                    Detailed charts on the sector that impacts us all.
                  </Typography>
                  <Typography component="div" sx={{ mt: .5, fontSize: '.8rem' }} color="text.very_light_desc">
                    <Typography sx={{ color: '#174c86', fontSize: 'inherit' }}>
                      Consumer:&nbsp;
                    </Typography>
                    Two-thrids of economy is Consumption. So consumer strength analsysis matters the most.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/* <Grid item md={4}>
              <Card variant="outlined" elevation={0} sx={{ borderRadius: '15px', bgcolor: '#fafafa', height: '100%' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', '& a': { cursor: 'pointer' } }} gutterBottom>
                    <PieChartRoundedIcon sx={{ fontSize: '1.2rem', color: '#174c86', mr: 1 }} />
                    <Typography sx={{ fontSize: 14, color: '#132F4C', fontWeight: 600 }} color="text.very_light_desc" >
                      Tools
                    </Typography>
                  </Box>
                  <Typography component="div" sx={{ mt: 1.5, fontSize: '.8rem' }} color="text.very_light_desc">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                  </Typography>
                </CardContent>
              </Card>
            </Grid> */}
          </Grid>
        </Container>
      </Container>
      <Container maxWidth="lg" sx={{ py: '5rem', textAlign: 'center', }}>
        {/* <Typography className="text-center pt-4" component="div" >
          <Typography
            sx={{
              fontSize: '40px',
              fontWeight: '600',
              whiteSpace: 'pre-line'
            }}
            className={` hero-text`}
            component="div"
            variant="h2"
          >
            {`"...value of $2000/mth 'Terminal' for\none Starbucks coffee"`}
          </Typography>
        </Typography> */}
        <Typography variant="h2"
          sx={{
            fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
            fontWeight: 800,
            lineHeight: '1.2222222222222223',
            color: '#132F4C',
            marginTop: '10px',
            // maxWidth: '630px',
            textAlign: 'center',
            mb: 4,
          }}
          className={` hero-text`}
        >
          {`"...value of $2000/mth 'Terminal' for`}<br />&nbsp;
          <Typography sx={{ fontWeight: 'inherit', fontSize: 'inherit', whiteSpace: 'no-wrap', color: '#2DA1EE', background: 'linear-gradient(to right, #2DA1EE, #174c86)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent', }}>
            one Starbucks coffee
          </Typography>{`"`}
        </Typography>
        <Typography className="text-capitalize text-dark pt-4" variant="p" align="center" color="textSecondary" component="div" style={{ fontSize: '1rem', my: 4, textTransform: 'normal' }}>
          {`GET FULL ACCESS TO GRUFITY`}
        </Typography>
        <Elements
          stripe={stripePromise}
        >
          <LandingCheckout
            pricePlans={pricePlans}
          />
        </Elements>
        <Box component="div" sx={{ background: '#fafafa', py: 9, px: 1, mt: 17, mb: 1, border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '7px' }}>
          <Typography variant="h5" sx={{
            fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
            fontWeight: 600, lineHeight: '1.2222222222222223',
            color: '#132F4C', mb: 5
          }}>
            {`"Rule `}
            <Typography sx={{ fontWeight: 'inherit', fontSize: 'inherit', whiteSpace: 'no-wrap', color: '#2DA1EE', background: 'linear-gradient(to right, #2DA1EE, #174c86)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent', }}>
              No. 1
            </Typography>
            {` is never lose money."`}
            <Typography variant="p" sx={{ fontSize: '1rem', fontWeight: '500' }}> &nbsp;&nbsp;-&nbsp;Warren&nbsp;Buffett</Typography>
          </Typography>
          <Typography variant="h5"
            sx={{
              fontWeight: 800,
              lineHeight: '1.2222222222222223',
              color: '#132F4C',
              marginTop: '10px',
              // maxWidth: '630px',
              textAlign: 'center',
              mt: 2, fontSize: '1.62rem'
            }}
            className={` hero-text`}
          >
            {`"...never buy a stock without a complete look at`}&nbsp;
            <Typography sx={{ fontWeight: 'inherit', fontSize: 'inherit', whiteSpace: 'no-wrap', color: '#2DA1EE', background: 'linear-gradient(to right, #2DA1EE, #174c86)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent', }}>
              Grufity
            </Typography>{`."`}
          </Typography>
        </Box>
        <Typography variant="body1" color="text.description" component="div" sx={{ mt: 4 }}>
        </Typography>
      </Container>
      <Container maxWidth="xl" sx={{ height: '100%', py: 5 }}>
        <Container maxWidth="lg">
          <Box component="div">
            <Typography variant="body1" sx={{ fontWeight: 700, color: '#174c86', }}>Features</Typography>
            <Typography variant="h2" sx={{
              fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
              fontWeight: 800,
              lineHeight: '1.2222222222222223',
              color: '#132F4C',
              marginTop: '10px',
              // maxWidth: '670px',
              // whiteSpace: 'no-wrap',
            }}
            >
              Grufity provides a 360° view on&nbsp;
              <Typography
                sx={{ fontWeight: 'inherit', fontSize: 'inherit', whiteSpace: 'no-wrap', color: '#2DA1EE', background: 'linear-gradient(to right, #2DA1EE, #174c86)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent', }}
              >
                Stocks and Economy
              </Typography>
            </Typography>
          </Box>
          <TableContainer className="shadow-none" sx={{ my: '3rem', boxShadow: 0, borderRadius: '15px', border: 1, borderColor: 'rgba(224, 224, 224, 1)' }}>
            <Table aria-label="caption table" size="small" sx={{ bgcolor: '#fafafa', }}>
              <TableBody sx={{}}>
                {pricing_comparison_rows.map((row) => (
                  <TableRow key={row.feature_name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" alignItems="center" sx={{ '& a': { fontSize: 14, color: '#174c86', fontWeight: 600, textDecoration: 'none' }, '& a:hover': { textDecoration: 'underline' } }}>
                      <p className="mb-0 text-capitalize" dangerouslySetInnerHTML={{ __html: row.feature_name }} style={{ fontWeight: 500, }} />
                      {row.feature_desc && <Typography variant="caption" component="div" color="text.description" sx={{ my: 1, '& a': { fontSize: 12, color: '#174c86', fontWeight: 500, textDecoration: 'none' }, }} dangerouslySetInnerHTML={{ __html: row.feature_desc }} />}
                    </TableCell>
                    <TableCell align="center" sx={{ color: '#174c86' }}>
                      {/* {row.premium ? <CheckCircleRoundedIcon sx={{ fontSize: '1.7rem' }} /> : ''} */}
                      <CheckCircleRoundedIcon sx={{ fontSize: '1.7rem' }} />
                      <p className="text-muted mb-0">{row.premium_desc}</p>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Container>
      {/* <Box component="div" className="pri-item container justify-content-center px-3">
        <Box component="div" className="pri-header d-flex align-items-center">
          <Box component="div" className={`${isMobile ? 'w-100' : 'w-50 mx-3'} `}>
            <Box component="div" className="mt-2 mb-4">
              <Avatar className="m-auto" style={{ backgroundColor: '#174c86' }}>
                <BusinessRoundedIcon />
              </Avatar>
              <h5 className="pri-title mt-3 mb-1 text-uppercase">{`ENTERPRISE`}</h5>
            </Box>
            <Box component="div" className="pri-line mb-0"></Box>
            <Box component="div" className="pri-classification py-3">
              <span className="mb-0">{`Customizable data and research solutions`}</span>
              <br />
              <span>{`for small and large enterprises`}</span>
            </Box>
            <Box component="div" className="pri-line"></Box>
            <Box component="div" className="price-btn">
              <a href="javascript:void(Tawk_API.toggle())">
                <button
                  className="btn btn-primary text-uppercase w-100"
                >
                  <p className="mb-0 mx-auto" style={{ fontSize: '14px' }}>{`Request Quote`}</p>
                </button>
              </a>
            </Box>
            <Box component="div">
            </Box>
          </Box>
          {!isMobile &&
            <Box component="div" className="{pri-content} h-75 px-5 w-50 mx-3" style={{ borderLeft: '1px solid #174c862e' }}>
              <Box component="div" className="pri-details text-left">
                <p className="pri-desc">{`Full Website Access`}</p>
                <p className="pri-desc">{`Customised Data`}</p>
                <p className="pri-desc">{`Unlimited API Access`}</p>
                <p className="pri-desc">{`Custom Charting`}</p>
                <p className="pri-desc">{`Back Office Support`}</p>
              </Box>
            </Box>}
        </Box>
      </Box> */}
      <Container maxWidth="md" sx={{ py: '6rem' }}>
        <Box className="text-center py-5" component="div" sx={{ my: '1rem', textAlign: "center" }}>
          <Box className="container" component="div">
            <Typography className="text-capitalize" component="h2" variant="h5" style={{ fontWeight: '600', color: '#132F4C' }}>
              {`Don't just follow experts`}
            </Typography>
            <Typography
              sx={{
                my: 3,
                fontSize: '48px',
                fontWeight: '600',
                color: '#2DA1EE', background: 'linear-gradient(to right, #2DA1EE, #174c86)', '-webkit-background-clip': 'text', '-webkit-text-fill-color': 'transparent',
              }}
              className={`text-capitalize`}
              component="h2"
              variant="h2"
            >
              {`Aim to be one`}
            </Typography>
            <Box className="text-center py-4 px-3" component="div" sx={{}}>
              <Typography className="desc" variant="subtitle1" color="text.description">
                {`If you have been mostly looking at the price chart and hoping to make a quick buck on a stock then chances are that you haven't yet discovered the true joy of investing.
                  Price charts are important but know that they are just one clue out of many. The real joy of investing is in exploring many stocks and finding a few gems that will multi-fold
                  your investments over the years.`}
              </Typography>
              {/* <Typography className="pt-2 px-2" variant="subtitle1" sx={{ mt: 4, fontWeight: '600', color: '#174c86' }}>
                {`This exploration eventually becomes a beautiful hobby.`}
              </Typography> */}
            </Box>
          </Box>
        </Box>
      </Container >
      <Container maxWidth="lg" sx={{ py: '5rem', }} className="w-100 text-center help-center- mt-5">
        <FAQs
          pricePlans={pricePlans}
          header={
            <>
              <Typography component="div" variant="h2" sx={{ fontWeight: 700, color: '#174c86', textAlign: 'center', fontSize: '1.5rem', pb: 4 }} itemProp="name">
                {`Frequently Asked Questions`}
              </Typography>
              <Divider sx={{ width: "15%", mx: 'auto' }} />
            </>
          }
        />
        {/* <Box component="div" className="w-100 text-center help-center- mt-5">
          <Box component="div" className="pb-5">
            <Typography component="div" variant="h2" sx={{ fontWeight: 700, color: '#174c86', textAlign: 'center', fontSize: '1.5rem', pb: 4 }} itemProp="name">
              {`Frequently Asked Questions`}
            </Typography>
            <Divider sx={{ width: "15%", mx: 'auto' }} />
            <Typography variant="h2" sx={{ fontWeight: 700, color: '#174c86', textAlign: 'center', fontSize: '1.2rem' }}>{`Help Center`}</Typography>
            <Typography variant="body1" gutterBottom component="div"
              sx={{
                color: 'text.description', lineHeight: '2', textAlign: 'center',
                color: 'text.description', fontSize: '.9rem', my: 4
              }}>{`Here are some frequently asked questions`}</Typography>
            <Box component="div" className="row qanda" sx={{ mt: 8 }}>
              <Box component="div" className="col-md-6 text-left">
                {qAndA && qAndA.length > 0 && qAndA.map((qa, ind) => {
                  return (qa.a && qa.a !== "" ?
                    <Accordion defaultExpanded itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
                      <AccordionSummary
                        aria-controls={`question${ind}-content`}
                        id={`question${ind}-header`}
                        expandIcon={<ExpandMoreRoundedIcon sx={{ color: '#174c86', }} />}
                      >
                        <Typography variant="p" component="h3" sx={{ lineHeight: 1.7, letterSpacing: '0.00938em', fontSize: '.875rem', color: '#174c86' }} className="font-weight-bold" dangerouslySetInnerHTML={{ __html: qa.q }} itemProp="name" />
                      </AccordionSummary>
                      <AccordionDetails itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
                        <Typography variant="p" component="p"
                          sx={{ lineHeight: 1.7, letterSpacing: '0.00938em', fontSize: '.875rem', color: '#434166', '& a': { textDecoration: 'none', color: "#174ea6" }, '& a:hover': { textDecoration: 'underline' } }}
                          dangerouslySetInnerHTML={{ __html: replaceTagsWithLinks(qa.a, replacements) }} itemProp="text"
                        />
                      </AccordionDetails>
                    </Accordion> : null)
                })}
              </Box>
            </Box>
          </Box>
        </Box> */}
      </Container>
      {/* <CouponCard {...props}>
        <Box component="div" class="container" sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', }} >
          <Box component="div" class="coupon-card"
            sx={{
              fontSize: '12px', background: 'linear-gradient(135deg, #020B4A, #174c86) !important',
              color: '#fff', textAlign: 'center', padding: '20px 30px', borderRadius: '15px',
              boxShadow: ' 0 10px 10px 0 rgba(0,0,0,0.15)', position: 'relative !important',
            }}>
            <Typography variant="h6" sx={{ fontSize: '14px !important', fontWeight: '600', }}>
              50% OFF - ONLY ON ANNUAL PLAN
            </Typography>
            <Box component="div" class="coupon-row" onClick={copyCoupon} sx={{ display: 'flex', alignItems: 'center', margin: '15px auto', width: 'fit-content', }}>
              <Typography variant="span" id="cpnCode"
                sx={{
                  border: '1px dashed #fff', padding: '5px 20px', borderRight: '0', fontSize: '.7rem !important', fontWeight: '600 !important', borderRadius: '5px 0 0 5px',
                }}
              >50OFF</Typography>
              <Typography variant="span" id="cpnBtn"
                sx={{
                  border: '1px solid #fff', background: ' #fff', padding: '5px 20px', borderRadius: '0 5px 5px 0',
                  color: '#174c86', cursor: 'pointer', fontSize: '.7rem !important', fontWeight: '600 !important',
                }}
              >COPY CODE</Typography>
            </Box>
            <Typography variant="p" sx={{ color: '#ffffffc7' }}>Valid Till: 20Dec, 2021</Typography>
            <Box component="div" class="circle1"
              sx={{
                left: '-12px', background: '#fafafa', width: '25px', height: '25px', borderRadius: '50%',
                position: 'absolute !important', top: '50%', transform: 'translateY(-50%)',
              }}
            />
            <Box component="div" class="circle2"
              sx={{
                right: '-12px', background: '#fafafa', width: '25px', height: '25px', borderRadius: '50%',
                position: 'absolute !important', top: '50%', transform: 'translateY(-50%)',
              }}
            />
          </Box>
        </Box>
      </CouponCard> */}
    </Box >
  )
}

export default Landing
