// import { connect } from "react-redux";
// import { Box, Button, Typography } from "@mui/material";
// import { useTheme } from '@mui/material/styles';
// import { doIncAction, doDecAction } from "../src/actions";
// import { bindActionCreators } from "redux";
// import Counter from "../src/components/Counter";
// import Layout from "../src/components/Layout";
// import initFirebase from "../firebase";
// import Alert from '@mui/material/Alert';
// import Slide from '@mui/material/Slide';
// import { firebase } from '../firebase'
// import EmailIcon from '@mui/icons-material/Email';
// import {
//   useAuthUser,
//   withAuthUser,
//   withAuthUserTokenSSR,
// } from 'next-firebase-auth'
// import Link from "next/link";
// import useAuth from "../src/hook/auth";
import { useRouter } from "next/router";
// import { useEffect, useState } from "react";
import getPageMetaTags from "../lib/meta/_getMetaTags";
import getPricePlans from "../lib/pricing/_getPricePlans";
// import onPageInit from "../src/actions/onPageInit";
import Landing from "../src/components/LandingPage";
// import useOnlineStatus from "../src/components/_common/useOnlineStatus";

const Home = (props) => {
  const { asPath } = useRouter();
  // const [mounted, setMounted] = useState(false)

  // if (!mounted) {
  //   onPageInit(asPath);
  // }

  // useEffect(() => {
  //   setMounted(true)
  // }, [])

  const { pricePlans, pageLoading } = props || {}
  // const { toggleColorMode, themeMode } = props || {}
  // const auth = useAuth();
  // const theme = useTheme()
  // const { user } = auth || {}

  return (<Landing pricePlans={pricePlans} pageLoading={pageLoading} />);
};

export async function getStaticProps() {
  const { plans } = (await getPricePlans()) || {}
  const meta_tags = (await getPageMetaTags('/', false)) || {}
  const { getMetaTags } = meta_tags || {}
  return {
    props: {
      pricePlans: plans || {},
      meta_tags: getMetaTags?.tags || null
    },
  }
}

export default (Home);
